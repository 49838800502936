import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import history from 'services/history';

import ApplicationIcon from 'components/ApplicationIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import {
  faCheck,
  faTimes,
  faLink,
  faSpinner,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import {
  Select,
  Input,
  TextArea,
  Label,
  Error,
  Field,
} from '@cloudez/cloudez-design-system';

import { toast } from 'react-toastify';
import {
  createDomainRecordService,
  updateDomainRecordService,
  deleteDomainRecordService,
} from 'services/domain';
import { ThemeContext } from 'styled-components';
import { HoverText, Hover } from 'components/Hover';
import LinkModal from './LinkModal';
import RemoveModal from './RemoveModal';
import NoEditModal from './NoEditModal';
import { Card, CardContent, Buttons, WebsiteItem, WebsiteList } from './styles';

import { DomainDetailContext } from '../_context/state';

const DomainRecordCard: React.FC<{
  record: any;
  newRecord: any;
  setNewRecord: any;
  records: any;
  setRecords: any;
  isAdding: any;
  setIsAdding: any;
}> = ({
  record,
  newRecord,
  setNewRecord,
  records,
  setRecords,
  isAdding,
  setIsAdding,
}) => {
  const { websites } = useContext(DomainDetailContext);

  const [editingRecord, setEditingRecord] = useState({ ...record });
  const [errors, setErrors] = useState({} as any);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [linkModal, setLinkModal] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const [noEditModal, setNoEditModal] = useState<{
    show: boolean;
    ticketId: number | null;
  }>({
    show: false,
    ticketId: null,
  });

  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (isAdding) {
      setIsEditing(false);
    }
    // eslint-disable-next-line
  }, [isAdding]);

  const handleNewRecordChange = useCallback(
    e => {
      setNewRecord({
        ...newRecord,
        [e.target.name]: e.target.value,
      });
    },

    // eslint-disable-next-line
    [newRecord],
  );

  const handleEditRecordChange = useCallback(
    e => {
      setEditingRecord({
        ...editingRecord,
        [e.target.name]: e.target.value,
      });
    },
    // eslint-disable-next-line
    [editingRecord],
  );

  const addRecord = useCallback(async () => {
    setIsLoading(true);
    try {
      setErrors([]);
      const { data } = await createDomainRecordService(newRecord);
      setRecords([data, ...records]);
      setNewRecord();
      toast.success('Registro criado');
      // setIsAdding(false);
      setIsLoading(false);
    } catch (e) {
      setErrors(e?.response?.data);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [newRecord, records]);

  const editRecord = useCallback(async () => {
    setIsLoading(true);
    try {
      setErrors([]);
      const { data } = await updateDomainRecordService(
        editingRecord?.id,
        editingRecord,
      );

      toast.success('Registro atualizado');
      setRecords(records.map(rcrd => (rcrd?.id === data?.id ? data : rcrd)));
      setEditingRecord(data);
      setIsEditing(false);
      setIsLoading(false);
    } catch (e) {
      setErrors(e?.response?.data);
      setIsLoading(false);

      if (e?.response?.status === 400 && e?.response?.data?.ticket) {
        toast.error(
          `Não foi possível atualizar o registro. Ticket aberto #${e.response.data.ticket}`,
        );
        setNoEditModal({ show: true, ticketId: e.response.data.ticket });
      } else {
        toast.error('Erro ao atualizar o registro');
      }
    }
    // eslint-disable-next-line
  }, [editingRecord, records]);

  const deleteRecord = useCallback(async () => {
    try {
      setIsLoading(true);
      await deleteDomainRecordService(record?.id);
      setRecords(records.filter(rcrd => rcrd?.id !== record?.id));
      toast.success('Registro excluído.');
      setIsLoading(false);
    } catch (e) {
      toast.error(
        'Aconteceu algo de errado e foi possível excluir o registro :(',
      );
    }
    // eslint-disable-next-line
  }, [record, records]);

  const doubleClick = useCallback(() => {
    if (record.has_link) {
      setLinkModal(true);
    } else {
      setIsAdding(false);
      setIsEditing(true);
      setEditingRecord(record);
    }
    // eslint-disable-next-line
  }, [record]);

  const linkModalAction = useCallback(() => {
    setIsAdding(false);
    setIsEditing(true);
    setEditingRecord({ ...record, clean_link: true });
    // eslint-disable-next-line
  }, [record]);

  return (
    <>
      {modal && (
        <RemoveModal
          action={deleteRecord}
          show={modal}
          setShow={() => setModal(false)}
        />
      )}
      {linkModal && (
        <LinkModal
          record={record}
          action={linkModalAction}
          show={linkModal}
          setShow={() => setLinkModal(false)}
        />
      )}
      {noEditModal.show && (
        <NoEditModal
          show={noEditModal.show}
          setShow={value => setNoEditModal({ ...noEditModal, show: value })}
          ticketId={noEditModal.ticketId}
        />
      )}
      <Card>
        <CardContent>
          <Row
            style={{
              display: 'flex',
              height: '100%',
              width: '100%',
            }}
          >
            <Col sm="2">
              {isAdding && record?.id === newRecord?.id ? (
                <Field
                  style={{
                    marginBottom: '0',
                  }}
                >
                  <Label>Tipo</Label>
                  <Select
                    name="rtype"
                    value={newRecord?.rtype}
                    onChange={handleNewRecordChange}
                    height="40px"
                    block
                    error={!!errors?.rtype}
                  >
                    <option value="1">A</option>
                    <option value="5">CNAME</option>
                    <option value="12">PTR</option>
                    <option value="15">MX</option>
                    <option value="16">TXT</option>
                    <option value="28">AAAA</option>
                    <option value="33">SRV</option>
                    <option value="99">SPF</option>
                  </Select>
                  {errors?.rtype && <Error>{errors?.rtype[0]}</Error>}
                </Field>
              ) : (
                <p
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {record?.rtype === 1
                    ? 'A'
                    : record?.rtype === 5
                    ? 'CNAME'
                    : record?.rtype === 12
                    ? 'PTR'
                    : record?.rtype === 15
                    ? 'MX'
                    : record?.rtype === 16
                    ? 'TXT'
                    : record?.rtype === 28
                    ? 'AAAA'
                    : record?.rtype === 33
                    ? 'SRV'
                    : record?.rtype === 99
                    ? 'SPF'
                    : ''}
                </p>
              )}
            </Col>
            <Col sm="3">
              {(isAdding && record?.id === newRecord?.id) ||
              (isEditing && record?.id === editingRecord?.id) ? (
                <Field
                  style={{
                    marginBottom: '0',
                  }}
                >
                  <Label>Nome</Label>
                  <Input
                    height="40px"
                    block
                    name="name"
                    value={isAdding ? newRecord.name : editingRecord.name}
                    onChange={
                      isAdding ? handleNewRecordChange : handleEditRecordChange
                    }
                    error={!!errors.name}
                  />
                  {errors.name && <Error>{errors.name[0]}</Error>}
                </Field>
              ) : (
                <p
                  onDoubleClick={doubleClick}
                  style={{
                    fontWeight: 'normal',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {record.name}
                </p>
              )}
            </Col>

            <Col sm={record.has_link ? '5' : '6'}>
              {(isAdding && record?.id === newRecord?.id) ||
              (isEditing && record?.id === editingRecord?.id) ? (
                newRecord?.rtype === '16' ||
                editingRecord?.rtype === 16 ||
                editingRecord?.rtype === '16' ? (
                  <Field
                    style={{
                      marginBottom: '0',
                    }}
                  >
                    <Label>Conteúdo</Label>
                    <TextArea
                      block
                      height="40px"
                      name="text"
                      value={isAdding ? newRecord.text : editingRecord.text}
                      onChange={
                        isAdding
                          ? handleNewRecordChange
                          : handleEditRecordChange
                      }
                      error={!!errors.text}
                    />
                    {errors.text && <Error>{errors.text[0]}</Error>}
                  </Field>
                ) : (
                  <Field
                    style={{
                      marginBottom: '0',
                      position: 'relative',
                    }}
                  >
                    <Label>Conteúdo</Label>

                    <Input
                      height="40px"
                      block
                      name="host"
                      value={isAdding ? newRecord.host : editingRecord.host}
                      onChange={
                        isAdding
                          ? handleNewRecordChange
                          : handleEditRecordChange
                      }
                      onFocus={() => {
                        setInputFocus(true);
                      }}
                      error={!!errors.host}
                    />
                    {inputFocus &&
                      (newRecord?.rtype === '5' ||
                        newRecord?.rtype === 5 ||
                        editingRecord?.rtype === 5 ||
                        editingRecord?.rtype === '5') &&
                      websites?.results?.find(w =>
                        editingRecord.host || newRecord.host
                          ? w?.domain?.includes(
                              editingRecord.host || newRecord.host,
                            )
                          : false,
                      ) && (
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: 2,
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              position: 'fixed',
                              top: '0px',
                              right: '0px',
                              bottom: '0px',
                              left: '0px',
                            }}
                            onClick={() => setInputFocus(false)}
                          />
                          <WebsiteList>
                            <WebsiteItem>
                              <FontAwesomeIcon icon={faLink} />
                              <div>
                                <span>Selecione um website</span>
                              </div>
                            </WebsiteItem>
                            {websites?.results?.map(
                              w =>
                                w?.domain?.includes(
                                  editingRecord.name || newRecord.name,
                                ) && (
                                  <WebsiteItem
                                    key={w?.id}
                                    onClick={() => {
                                      if (isAdding) {
                                        setNewRecord({
                                          ...newRecord,
                                          host: w.cloud.fqdn,
                                          nodeapps_id: [w?.id],
                                        });
                                      } else {
                                        setEditingRecord({
                                          ...editingRecord,
                                          host: w.cloud.fqdn,
                                          nodeapps_ids: [w?.id],
                                        });
                                      }
                                      setInputFocus(false);
                                    }}
                                  >
                                    <ApplicationIcon
                                      icon={w.type.slug}
                                      width="25px"
                                      height="25px"
                                    />
                                    <div>
                                      <span>{w.domain || w.name}</span>
                                      <p>{w.cloud.display}</p>
                                    </div>
                                  </WebsiteItem>
                                ),
                            )}
                          </WebsiteList>
                        </div>
                      )}
                    {errors.host && <Error>{errors.host[0]}</Error>}
                  </Field>
                )
              ) : (
                <p
                  onDoubleClick={doubleClick}
                  style={{
                    fontWeight: 'normal',
                    width: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {record?.rtype === 1 ||
                  record?.rtype === 5 ||
                  record?.rtype === 2 ||
                  record?.rtype === 15 ||
                  record?.rtype === 33 ||
                  record?.rtype === 28
                    ? record.host
                    : record.text}
                </p>
              )}
            </Col>

            {(record.has_link || editingRecord.has_link) && (
              <Col
                sm="1"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Hover>
                  <FontAwesomeIcon
                    icon={faLink}
                    size="2x"
                    color={theme.successNew}
                  />
                  <HoverText>Registro vinculado à uma aplicação</HoverText>
                </Hover>
              </Col>
            )}

            <Col sm="1">
              <Buttons>
                {isLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <>
                    <FontAwesomeIcon
                      onClick={
                        isAdding && record?.id === newRecord?.id
                          ? addRecord
                          : isEditing
                          ? editRecord
                          : doubleClick
                      }
                      icon={
                        (isAdding && record?.id === newRecord?.id) || isEditing
                          ? faCheck
                          : faEdit
                      }
                    />
                    <FontAwesomeIcon
                      icon={
                        (isAdding && record?.id === newRecord?.id) || isEditing
                          ? faTimes
                          : faTrashAlt
                      }
                      onClick={
                        (isAdding && record?.id === newRecord?.id) || isEditing
                          ? () => {
                              setIsEditing(false);
                              setNewRecord();
                            }
                          : () => setModal(true)
                      }
                    />
                  </>
                )}
              </Buttons>
            </Col>
          </Row>
          {((isAdding &&
            (newRecord?.rtype === '15' || newRecord?.rtype === 15)) ||
            (isEditing &&
              (editingRecord?.rtype === 15 ||
                editingRecord?.rtype === '15'))) && (
            <Row
              style={{
                width: '100%',
              }}
            >
              <Col xs="12" sm="4">
                {((isAdding && record?.id === newRecord?.id) ||
                  (isEditing && record?.id === editingRecord?.id)) && (
                  <Field
                    style={{
                      marginBottom: '0',
                    }}
                  >
                    <Label>Prioridade</Label>
                    <Input
                      block
                      height="40px"
                      name="priority"
                      value={
                        isAdding ? newRecord.priority : editingRecord.priority
                      }
                      onChange={
                        isAdding
                          ? handleNewRecordChange
                          : handleEditRecordChange
                      }
                      error={!!errors.priority}
                    />
                    {errors.priority && <Error>{errors.priority[0]}</Error>}
                  </Field>
                )}
              </Col>
            </Row>
          )}

          {((isAdding &&
            (newRecord?.rtype === '33' || newRecord?.rtype === 33)) ||
            (isEditing &&
              (editingRecord?.rtype === 33 ||
                editingRecord?.rtype === '33'))) && (
            <Row
              style={{
                width: '100%',
              }}
            >
              <Col xs="12" sm="6">
                {((isAdding && record?.id === newRecord?.id) ||
                  (isEditing && record?.id === editingRecord?.id)) && (
                  <Field
                    style={{
                      marginBottom: '0',
                    }}
                  >
                    <Label>Prioridade</Label>
                    <Input
                      block
                      height="40px"
                      name="priority"
                      value={
                        isAdding ? newRecord.priority : editingRecord.priority
                      }
                      onChange={
                        isAdding
                          ? handleNewRecordChange
                          : handleEditRecordChange
                      }
                      error={!!errors.priority}
                    />
                    {errors.priority && <Error>{errors.priority[0]}</Error>}
                  </Field>
                )}
              </Col>
              <Col xs="12" sm="3">
                {((isAdding && record?.id === newRecord?.id) ||
                  (isEditing && record?.id === editingRecord?.id)) && (
                  <Field
                    style={{
                      marginBottom: '0',
                    }}
                  >
                    <Label>Porta</Label>
                    <Input
                      block
                      height="40px"
                      name="port"
                      value={isAdding ? newRecord.port : editingRecord.port}
                      onChange={
                        isAdding
                          ? handleNewRecordChange
                          : handleEditRecordChange
                      }
                      error={!!errors.port}
                    />
                    {errors.port && <Error>{errors.port[0]}</Error>}
                  </Field>
                )}
              </Col>
              <Col xs="12" sm="3">
                {((isAdding && record?.id === newRecord?.id) ||
                  (isEditing && record?.id === editingRecord?.id)) && (
                  <Field
                    style={{
                      marginBottom: '0',
                    }}
                  >
                    <Label>Peso</Label>
                    <Input
                      block
                      height="40px"
                      name="weight"
                      value={isAdding ? newRecord.weight : editingRecord.weight}
                      onChange={
                        isAdding
                          ? handleNewRecordChange
                          : handleEditRecordChange
                      }
                      error={!!errors.weight}
                    />
                    {errors.weight && <Error>{errors.weight[0]}</Error>}
                  </Field>
                )}
              </Col>
            </Row>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default React.memo(DomainRecordCard);
