import React, { useState } from 'react';

import { updateDomainService } from 'services/domain';
import history from 'services/history';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faCopy,
  faEdit,
  faPen,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import { Input, Field, Button } from '@cloudez/cloudez-design-system';

import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from '../styles';
import { DomainText } from './styles';

const EditDomainModal: React.FC<{
  domain: any;
  setShow: any;
}> = ({ domain, setShow }) => {
  const [loading, setLoading] = useState(false);

  const [nameservers, setNameservers] = useState(['', '', '', '', '']);

  const handleInputChange = (index: number, value: string) => {
    setNameservers(prev => {
      const updated = [...prev];
      updated[index] = value;
      return updated;
    });
  };

  const updateDomain = async () => {
    try {
      setLoading(true);
      const filteredNameservers = nameservers.filter(ns => ns?.trim());

      await updateDomainService(domain.id, {
        current_nameservers: filteredNameservers,
      });

      setLoading(false);
      setShow(false);
      toast.success('Domínio atualizado.');
    } catch (e) {
      setLoading(false);
      if (e?.response?.status === 400 && e?.response?.data?.ticket) {
        toast.error(
          `Não foi possível atualizar o domínio. Ticket aberto #${e.response.data.ticket}`,
        );
        history.push(`/tickets/${e.response.data.ticket}`);
      } else {
        toast.error('Erro ao atualizar o domínio');
      }
    }
  };

  return (
    <>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
        <Text>
          <Title>Editar Nameservers</Title>
          <Subtitle>Configure os nameservers do seu domínio</Subtitle>
        </Text>
      </Header>
      <Content>
        <DomainText>
          Digite os nameservers do domínio <b>{domain.domain}</b>:
        </DomainText>
        <Field style={{ marginBottom: 10 }}>
          <Input
            block
            value={nameservers[0]}
            onChange={e => handleInputChange(0, e.target.value)}
            placeholder="ns1.cloudez.io"
            background
          />
        </Field>
        <Field style={{ marginBottom: 10 }}>
          <Input
            block
            value={nameservers[1]}
            onChange={e => handleInputChange(1, e.target.value)}
            placeholder="ns2.cloudez.io"
            background
          />
        </Field>
        <Field style={{ marginBottom: 10 }}>
          <Input
            block
            value={nameservers[2]}
            onChange={e => handleInputChange(2, e.target.value)}
            placeholder="ns3.cloudez.io"
            background
          />
        </Field>
        <Field style={{ marginBottom: 10 }}>
          <Input
            block
            value={nameservers[3]}
            onChange={e => handleInputChange(3, e.target.value)}
            placeholder="ns4.cloudez.io"
            background
          />
        </Field>
        <Field style={{ marginBottom: 10 }}>
          <Input
            block
            value={nameservers[4]}
            onChange={e => handleInputChange(4, e.target.value)}
            placeholder="ns5.cloudez.io"
            background
          />
        </Field>
      </Content>
      <Footer style={{ justifyContent: 'space-between' }}>
        <Button onClick={() => setShow(false)}>Fechar</Button>
        <Button icon onClick={updateDomain}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Salvar'}
        </Button>
      </Footer>
    </>
  );
};

export default EditDomainModal;
