import React, { useState } from 'react';
import history from 'services/history';

import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTicket } from '@fortawesome/free-solid-svg-icons';

import { Modal, Input, Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { ModalProps } from 'types';
import { useLayout } from 'hooks/layout';
import { HttpsText } from './styles';

interface NoEditModalProps extends ModalProps {
  ticketId: number | null;
}

const NoEditModal: React.FC<NoEditModalProps> = ({
  ticketId,
  show,
  setShow,
}) => {
  const [remove, setRemove] = useState('');
  const [loading, setLoading] = useState(false);
  const theme = useLayout();

  return (
    <Modal width="455px" show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faTicket} />
        </Icon>
        <Text>
          <Title>Alteração de apontamento DNS</Title>
          <Subtitle>Edição da entrada de registro</Subtitle>
        </Text>
      </Header>

      <Content>
        <HttpsText>
          <>
            Recebemos sua solicitação de alteração de apontamento DNS e sua
            solicitação será processada em breve. Você pode acompanhar a
            solicitação através do <strong>Ticket #{ticketId}</strong>.
          </>
        </HttpsText>
      </Content>
      <Footer>
        <Button
          width="149px"
          icon
          disabled={!ticketId}
          onClick={() => {
            if (ticketId) {
              history.push(`/tickets/${ticketId}`);
            }
          }}
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Ver Ticket'}
        </Button>
        {/* <Button
          width="149px"
          icon
          onClick={() => {
            setShow(false);
          }}
        >
          Cancelar
        </Button> */}
      </Footer>
    </Modal>
  );
};

export default NoEditModal;
