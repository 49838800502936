import React, { useState } from 'react';

import { Modal } from '@cloudez/cloudez-design-system';

import FTPModal from './FTPModal';
import DebugModal from './DebugModal';
import HttpsModal from './HttpsModal';
import ShareModal from './ShareModal';
import SMTPModal from './SMTPModal';
import UpdateDomainModal from './UpdateDomainModal';
import AutomateDomainModal from './AutomateDomainModal';
import EditDomainModal from './EditDomainModal';

const ListingModal: React.FC<any> = ({
  type,
  show,
  setShow,
  data,
  action,
  updateField,
}) => {
  const [loading, setLoading] = useState();

  return (
    <Modal width="455px" show={show} setShow={setShow}>
      {(() => {
        switch (type) {
          case 'ftp':
            return (
              <FTPModal
                website={data}
                setShow={setShow}
                updateField={updateField}
              />
            );
          case 'debug':
            return (
              <DebugModal
                website={data}
                action={action}
                setShow={setShow}
                loading={loading}
                setLoading={setLoading}
              />
            );
          case 'no-https':
          case 'https-create':
          case 'https-loading':
          case 'https-remove':
            return (
              <HttpsModal
                website={data}
                action={action}
                setShow={setShow}
                type={type}
                loading={loading}
                setLoading={setLoading}
              />
            );
          case 'share':
            return (
              <ShareModal
                data={data}
                action={action}
                setShow={setShow}
                loading={loading}
                setLoading={setLoading}
              />
            );
          case 'smtp':
            return <SMTPModal email={data} setShow={setShow} />;
          case 'updateDomain':
            return (
              <UpdateDomainModal
                domain={data}
                setShow={setShow}
                loading={loading}
                setLoading={setLoading}
              />
            );
          case 'automateDomain':
            return <AutomateDomainModal domain={data} setShow={setShow} />;
          case 'editDomain':
            return <EditDomainModal domain={data} setShow={setShow} />;

          default:
            return null;
        }
      })()}
    </Modal>
  );
};

export default ListingModal;
