import React, { useContext, useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGlobeAmericas,
  faShareAlt,
  faSyncAlt,
  faCog,
  faStar,
  faSpinner,
  faTriangleExclamation,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';

import {
  Card,
  ListingItem,
  ApplicationValue,
  ApplicationName,
} from 'components/Listing/styles';

import { AcessosList, Acesso, AcessoHover, Value } from 'components/Acessos';
import { Wrapper } from 'components/ApplicationIcon/styles';
import ListingModal from 'components/ListingModal';
import { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import moment from 'moment';
import { DomainIcon } from '../styles';
import { DomainListingContext } from '../_context/state';

const DomainCard = ({ domain }) => {
  const { user } = useAuth();

  const [modal, toggleModal] = useState(false);
  const [data, setData] = useState(null);
  const [type, setType] = useState('ftp');

  const [showAccess, setShowAccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useContext(ThemeContext);
  const { updateDomain } = useContext(DomainListingContext);

  const openModal = (e, type, data) => {
    e.preventDefault();
    e.stopPropagation();
    setData(data);
    setType(type);
    toggleModal(true);
  };

  return (
    <ListingItem key={domain.id}>
      {modal && (
        <ListingModal
          show={modal}
          setShow={() => toggleModal(false)}
          type={type}
          data={data}
          action={updateDomain}
        />
      )}
      <Link to={`/domains/${domain.id}`}>
        <Card>
          <Row>
            <Col
              xs={showAccess ? '4' : '10'}
              sm={showAccess ? '4' : '10'}
              md="3"
            >
              <ApplicationValue>
                <Wrapper>
                  <DomainIcon disable={!domain.is_active}>
                    <FontAwesomeIcon icon={faGlobeAmericas} size="3x" />
                  </DomainIcon>
                </Wrapper>
                {!showAccess && (
                  <ApplicationName>{domain.domain}</ApplicationName>
                )}
              </ApplicationValue>
            </Col>
            <Col md="2" className="d-none d-sm-none d-md-flex">
              {domain.expires_at && (
                <ApplicationValue style={{ fontWeight: 'normal' }}>
                  <p>
                    {moment(domain.expires_at)
                      .subtract(1, 'years')
                      .format('DD/MM')}
                  </p>
                </ApplicationValue>
              )}
            </Col>
            <Col className="d-none d-sm-none d-md-flex">
              <ApplicationValue style={{ fontWeight: 'normal' }}>
                <p>{domain.last_modified}</p>
              </ApplicationValue>
            </Col>

            {domain.expires_at && (
              <Col md="4" className="d-none d-sm-none d-md-flex">
                <ApplicationValue style={{ fontWeight: 'normal', gap: '5px' }}>
                  <p>
                    {`Domínio vence dia ${moment(domain.expires_at).format(
                      'DD/MM/YYYY',
                    )}.`}{' '}
                  </p>
                </ApplicationValue>
              </Col>
            )}
            {!showAccess && (
              <Col className="d-sm-flex d-md-none">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    style={{
                      color: '#64728C',
                    }}
                    onClick={e => {
                      e.preventDefault();
                      setShowAccess(true);
                    }}
                  />
                </div>
              </Col>
            )}

            <Col
              xl={domain.expires_at ? '1' : '2'}
              lg="5"
              md="5"
              sm="8"
              xs="8"
              className={!showAccess && 'd-none d-md-flex'}
              onClick={e => {
                e.preventDefault();
                setShowAccess(false);
              }}
              style={{
                background: showAccess && theme.interface3,
                height: '100%',
                width: '100%',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
              }}
            >
              <AcessosList showAccess={showAccess}>
                {/* {user.is_staff && (
                  <Acesso
                    active
                    hover
                    onClick={e => openModal(e, 'updateDomain', domain)}
                  >
                    <FontAwesomeIcon icon={faSyncAlt} />
                    <AcessoHover>
                      <Value>
                        <span>Atualizar</span>
                      </Value>
                    </AcessoHover>
                  </Acesso>
                )} */}
                <Acesso
                  active
                  fixed={domain.is_active}
                  hover
                  onClick={e => openModal(e, 'editDomain', domain)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                  <AcessoHover>
                    <Value>
                      <span>Editar</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
                <Acesso
                  active
                  fixed={domain.is_active}
                  hover
                  onClick={e => openModal(e, 'automateDomain', domain)}
                >
                  <FontAwesomeIcon icon={faCog} />
                  <AcessoHover>
                    <Value>
                      <span>Automação</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
                <Acesso
                  active
                  hover
                  fixed={!!domain.shared_users.length}
                  onClick={e => {
                    openModal(e, 'share', domain);
                  }}
                >
                  <FontAwesomeIcon icon={faShareAlt} />
                  <AcessoHover>
                    <Value>
                      <span>Share</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
                <Acesso active fixed={domain.is_pinned} hover favorite>
                  <FontAwesomeIcon
                    onClick={async e => {
                      setLoading(true);
                      e.preventDefault();
                      await updateDomain(
                        'is_pinned',
                        !domain.is_pinned,
                        domain.id,
                      );
                      setLoading(false);
                    }}
                    icon={loading ? faSpinner : faStar}
                    spin={loading}
                  />
                  <AcessoHover>
                    <Value>
                      <span>Favorite</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
              </AcessosList>
            </Col>
          </Row>
        </Card>
      </Link>
    </ListingItem>
  );
};

export default DomainCard;
