import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCopy, faPen } from '@fortawesome/free-solid-svg-icons';

import { Input, Field, Button } from '@cloudez/cloudez-design-system';

import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from '../styles';
import { DomainText } from './styles';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';

const AutomateDomainModal: React.FC<{
  domain: any;
  setShow: any;
}> = ({ domain, setShow }) => {
  const [copiedText, setCopiedText] = useState('');

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedText(text);
    });
  };

  return (
    <>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faCog} />
        </Icon>
        <Text>
          <Title>Automatizar domínio</Title>
          <Subtitle>Nos deixe fazer o trabalho pra você</Subtitle>
        </Text>
      </Header>
      <Content>
        <DomainText>
          Para automatizar <b>{domain.domain}</b> mude o apontamento DNS no
          provedor de serviço em que foi comprado, para os seguintes DNS:
        </DomainText>
        <Field style={{ marginBottom: 0 }}>
          <Input block readOnly value="ns1.cloudez.io" background />
          <FontAwesomeIcon
            icon={faClipboard}
            onClick={() => handleCopyClick('ns1.cloudez.io')}
          />
        </Field>
        <Field style={{ marginBottom: 0 }}>
          <Input block readOnly value="ns2.cloudez.io" background />
          <FontAwesomeIcon
            icon={faClipboard}
            onClick={() => handleCopyClick('ns2.cloudez.io')}
          />
        </Field>
        <Field style={{ marginBottom: 0 }}>
          <Input block readOnly value="ns3.cloudez.io" background />
          <FontAwesomeIcon
            icon={faClipboard}
            onClick={() => handleCopyClick('ns3.cloudez.io')}
          />
        </Field>
        <Field style={{ marginBottom: 0 }}>
          <Input block readOnly value="ns4.cloudez.io" background />
          <FontAwesomeIcon
            icon={faClipboard}
            onClick={() => handleCopyClick('ns4.cloudez.io')}
          />
        </Field>
        <Field style={{ marginBottom: 0 }}>
          <Input block readOnly value="ns5.cloudez.io" background />
          <FontAwesomeIcon
            icon={faClipboard}
            onClick={() => handleCopyClick('ns5.cloudez.io')}
          />
        </Field>
      </Content>
      <Footer>
        <Button onClick={() => setShow(false)}>Fechar</Button>
      </Footer>
    </>
  );
};

export default AutomateDomainModal;
